import { ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ContentHelmet from '@components/common/ContentHelmet';
import ContentLayout from '@components/common/ContentLayout';
import { useNotif } from '@hooks/use-notif';
import { tenantService } from '@services/tenant';
import { Button, Card, Col, Divider, Form, Input, Modal, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import './EditTenant.scss';

const { confirm } = Modal;

export default function EditTenant() {
  const { id: id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const history = useHistory();
  const { addSuccess, addError } = useNotif();
  const [loading, setLoading] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState('');
  const [errorDomain, setErrorDomain] = useState(false);
  const [checkedDomain, setCheckedDomain] = useState(false);

  const onReset = () => {
    form.resetFields();
    setShowInput(false);
  };

  const { data: detailTenant, isValidating: loadingDetailTenant, mutate: refetchDetailTenant } = useSWR(
    [id, 'getDetailTenant'],
    tenantService.getDetailTenant
  );

  const handleAddDomain = async (val) => {
    setLoading(true);
    const domains = detailTenant?.data?.domains;
    domains.push(`${val.domains}.pesan.io`);
    try {
      await tenantService.updateTenant(id, {
        name: detailTenant?.data?.name,
        domains: domains,
      });
      addSuccess('Successfully Added New Custom Domains!');
      onReset();
      setLoading(false);
      refetchDetailTenant();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (detailTenant) {
      const val = detailTenant?.data;

      form.setFieldsValue({
        name: val.name,
        username: val.username,
        dbName: val.dbName,
        id: val._id,
      });
    }
  }, [detailTenant]);

  const handleEditTenant = async (val) => {
    setLoading(true);
    try {
      await tenantService.updateTenant(id, {
        name: val.name,
        domains: detailTenant?.data?.domains,
      });
      refetchDetailTenant();
      addSuccess('Successfully Edit Tenant!');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDeleteDomain = async (val) => {
    setLoading(true);
    try {
      await tenantService.updateTenant(id, {
        name: detailTenant?.data?.name,
        domains: val,
      });
      refetchDetailTenant();
      addSuccess('Successfully Delete Domain!');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleEditDomain = async (val) => {
    const domains = detailTenant?.data?.domains;
    let index = domains.indexOf(selectedDomain);
    if (~index) {
      domains[index] = val.domains;
    }
    setLoading(true);
    try {
      await tenantService.updateTenant(id, {
        name: detailTenant?.data?.name,
        domains: domains,
      });
      onReset();
      refetchDetailTenant();
      addSuccess('Successfully Edit Domain!');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCheckDomain = async () => {
    if (form.getFieldValue('domains')) {
      setCheckedDomain(false);
      setLoading(true);
      try {
        const response = await tenantService.checkDomain({ domain: `${form.getFieldValue('domains')}.pesan.io` });
        if (!response.data) {
          addSuccess('Domain Available');
          setLoading(false);
          setCheckedDomain(true);
          setErrorDomain(false);
        } else {
          addError('Domain Not Available');
          setLoading(false);
          setCheckedDomain(false);
          setErrorDomain(true);
        }
      } catch (error) {
        console.log(error);
        setErrorDomain(true);
        setLoading(false);
        setCheckedDomain(false);
      }
    }
  };

  const showConfirm = (val) => {
    let domains = detailTenant?.data?.domains;
    domains = domains.filter((domain) => domain !== val);
    confirm({
      title: 'Are you sure want to delete domain?',
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: `${val} will be deleted. This process cannot be undone.`,
      onOk() {
        handleDeleteDomain(domains);
      },
    });
  };

  const handleReplaceDomain = (val) => {
    if (val.includes('.pesanio.com')) {
      let domain = val.replace('.pesanio.com', '');
      form.setFieldsValue({ domains: domain });
    }
    if (val.includes('.pesan.io')) {
      let domain = val.replace('.pesan.io', '');
      form.setFieldsValue({ domains: domain });
    }
  };

  return (
    <ContentHelmet title="Edit Tenant">
      <ContentLayout
        loading={loadingDetailTenant || loading}
        BreadcrumbItems={[
          { href: '/', title: 'Home' },
          { href: '/tenants', title: 'Tenants' },
          { href: `/tenants/${id}/edit`, title: 'Edit Tenant' },
        ]}
      >
        <Space direction="vertical" size="small" className="full-width">
          <div className="detail-header">
            <ArrowLeftOutlined onClick={() => history.goBack()} />
            <Typography className="title-page">{detailTenant?.data?.name}</Typography>
          </div>
          <Divider type="horizontal" style={{ margin: 0 }} />
          <Card>
            <Typography className="title-section">Edit Tenant</Typography>
            <Form
              layout="vertical"
              form={form}
              size="middle"
              onFinish={(val) => {
                handleEditTenant(val);
              }}
            >
              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Tenant Name"
                    name="name"
                    rules={[{ required: true, message: 'Tenant name is required.' }]}
                  >
                    <Input placeholder="Input your tenant tame" />
                  </Form.Item>
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Username is required.' }]}
                  >
                    <Input placeholder="Input username for your tenant" maxLength={50} disabled />
                  </Form.Item>
                  <Form.Item
                    label="Database Name"
                    name="dbName"
                    rules={[{ required: true, message: 'Database name is required' }]}
                  >
                    <Input placeholder="Create name for database" disabled />
                  </Form.Item>
                  <Form.Item label="ID Tenant" name="id">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item style={{ textAlign: 'end' }}>
                    <Button id="button-save" type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Tenant Name"
                    name="name"
                    rules={[{ required: true, message: 'Tenant name is required.' }]}
                  >
                    <Input placeholder="Input your tenant tame" />
                  </Form.Item>
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Username is required.' }]}
                  >
                    <Input placeholder="Input username for your tenant" maxLength={50} disabled />
                  </Form.Item>
                  <Form.Item
                    label="Database Name"
                    name="dbName"
                    rules={[{ required: true, message: 'Database name is required' }]}
                  >
                    <Input placeholder="Create name for database" disabled />
                  </Form.Item>
                  <Form.Item label="ID Tenant" name="id">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item style={{ textAlign: 'end' }}>
                    <Button id="button-save" type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card style={{ marginTop: '0px !important' }}>
            <Typography className="title-section">Custom Domain</Typography>
            <Row gutter={[8, 16]} style={{ display: 'flex' }}>
              <Col span={6}>
                <Typography className="detail-key">Domains</Typography>
              </Col>
              <Col span={18}>
                {detailTenant?.data?.domains.map((item, index) => (
                  <div key={index}>
                    <Row style={{ marginBottom: 16 }}>
                      <Col span={8}>
                        <Typography>{item}</Typography>
                      </Col>
                      <Col span={8}>
                        <Button
                          type="primary"
                          style={{ marginRight: 16 }}
                          onClick={() => {
                            setShowInput(true);
                            handleReplaceDomain(item);
                            setSelectedDomain(item);
                            setCheckedDomain(false);
                          }}
                        >
                          Edit
                        </Button>
                        {detailTenant?.data?.domains.length > 1 && (
                          <Button
                            type="primary"
                            danger
                            onClick={() => {
                              showConfirm(item);
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}
              </Col>
            </Row>
            {showInput ? (
              <div style={{ marginTop: 24 }}>
                <Form
                  layout="vertical"
                  form={form}
                  size="middle"
                  onFinish={(val) => {
                    if (selectedDomain) {
                      handleEditDomain(val);
                    } else {
                      handleAddDomain(val);
                    }
                  }}
                  onValuesChange={(changedValue) => {
                    if (Object.keys(changedValue)[0] === 'domains') {
                      let pattern = /[^a-z0-9-.-]+/;
                      const newValue = changedValue?.domains?.replace(pattern, '');
                      form.setFieldsValue({
                        domains: newValue,
                      });
                    }
                  }}
                >
                  <Row className="align-center">
                    <Col span={8}>
                      <Form.Item
                        name="domains"
                        rules={[{ required: true, message: 'Domain is required.' }]}
                        validateStatus={errorDomain && 'error'}
                        help={errorDomain && 'Domain not available'}
                      >
                        <Input placeholder="Create your domain name" maxLength={50} onBlur={handleCheckDomain} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Typography style={{ marginLeft: 16, marginTop: -24 }}>.pesan.io</Typography>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={errorDomain || !checkedDomain}>
                      Save
                    </Button>
                    <Button htmlType="button" onClick={onReset} style={{ marginLeft: 16 }} type="primary" ghost>
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            ) : (
              <Button
                type="primary"
                style={{ marginTop: 32 }}
                onClick={() => {
                  setShowInput(true);
                  setCheckedDomain(false);
                }}
              >
                Add Custom Domain
              </Button>
            )}
          </Card>
        </Space>
      </ContentLayout>
    </ContentHelmet>
  );
}
